import { noop } from 'lodash';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterStateLock } from 'reports/utils/router';

import { IAppState } from 'reports/types';

import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as horiz from 'reports/models/horizon_profile';

import { ContextBarControls } from 'reports/components/ContextBar';
import { EditModeSaveCancelButtons } from 'reports/components/core/controls';

import { Flex } from 'reports/components/core/containers';
import { Form, handleRequestException } from 'reports/components/forms';
import { FormConsumer } from 'reports/components/forms/types';

import { actions as projActions } from 'reports/modules/project';
import * as auth from 'reports/modules/auth';

import ConditionSetWeatherEdit from './ConditionSetWeatherEdit';
import ConditionSetHorizonEdit from './ConditionSetHorizonEdit';
import ConditionSetComponentCharsEdit from './ConditionSetComponentCharsEdit';

import {
    ConditionSetSoilingEdit,
    ConditionSetCellTempEdit,
    ConditionSetAcLossesEdit,
    ConditionSetAdvancedEdit,
    ConditionSetMismatchEdit,
    ConditionSetTrackerEdit,
} from 'reports/modules/condition_sets/components/CondSetEditComponents';
import { makeCellTempParams } from 'reports/modules/condition_sets/components/common';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { surfaceCellTempErrors } from 'reports/modules/profile/components/ProfileEditor';
import { ProjectAssetPage } from 'reports/modules/project/views/ProjectAssetPage';

const FormActionButtons = ({ onClose = noop }) => (
    <FormConsumer>
        {({ submitForm, dirty, submitting, clearForm }) => (
            <EditModeSaveCancelButtons
                onSave={submitForm}
                hasChanges={dirty}
                onCancel={clearForm}
                onClose={onClose}
                disabled={submitting}
            />
        )}
    </FormConsumer>
);

type Props = {
    scenario: scen.Scenario;
    project: proj.Project;
    navigateToConditionSet: (project: proj.Project, scenario: scen.Scenario) => void;
    inlineControls?: boolean;
};

const ConditionSetEdit = ({ scenario, project, navigateToConditionSet, inlineControls }: Props) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const horizons = useSelector((state: IAppState) =>
        horiz.selectors.all(state, {
            filter: (obj) => obj.project_id === project.project_id,
        }),
    );

    const dispatch = useDispatch();
    const saveScenario = (scenario: scen.Scenario) => dispatch(scen.api.save(scenario));
    const setPrimaryScenario = (project, scenario) => dispatch(projActions.setPrimaryScenario(project, scenario));
    const updateDescription = (description) => dispatch(scen.scenarioSaver.get(scenario).patch({ description }));

    const preProcess = (scenario) => ({ ...scenario, cell_temp_parameters: makeCellTempParams(scenario) });
    const postProcess = (scenario) => ({
        ...scenario,
        cell_temp_parameters: Object.values(scenario.cell_temp_parameters),
    });
    const customExceptionHandler = (exc) => {
        const { fieldErrors, formErrors } = handleRequestException(exc);
        surfaceCellTempErrors(fieldErrors?.cell_temp_parameters);
        return { fieldErrors, formErrors };
    };

    return (
        <Form
            onSubmit={async (scenario) => {
                return await addPromiseToasts(
                    saveScenario(postProcess(scenario)).then(() => setPrimaryScenario(project, scenario)),
                    {
                        initial: 'Saving condition set...',
                        onSuccess: 'Successfully saved condition set.',
                        onCatch: 'Error saving condition set.',
                    },
                );
            }}
            baseValue={preProcess(scenario)}
            exceptionHandler={customExceptionHandler}
        >
            {({ dirty, submitForm, clearForm }) => (
                <ProjectAssetPage
                    title={scenario.description}
                    updateTitle={updateDescription}
                    inlineControls={
                        inlineControls ? (
                            <FormActionButtons onClose={() => navigateToConditionSet(project, scenario)} />
                        ) : null
                    }
                >
                    <RouterStateLock
                        title="Save condition set changes?"
                        prompt={`You have unsaved changes on ${scenario.description || 'this condition set'}.`}
                        cancellable={true}
                        showExitPrompt={dirty}
                        onSave={submitForm}
                        onDontSave={clearForm}
                    />
                    {!inlineControls && (
                        <ContextBarControls>
                            <FormActionButtons onClose={() => navigateToConditionSet(project, scenario)} />
                        </ContextBarControls>
                    )}
                    <Flex.ContainerV>
                        <ConditionSetWeatherEdit scenario={scenario} />
                        <ConditionSetHorizonEdit horizons={horizons} />
                        <ConditionSetSoilingEdit />
                        <ConditionSetCellTempEdit toggleBehavior="condition_sets" />
                        <ConditionSetComponentCharsEdit scenario={scenario} />
                        <ConditionSetMismatchEdit />
                        <ConditionSetAdvancedEdit />
                        <ConditionSetAcLossesEdit />
                        {user.hasSingleAxisTrackersAccess() && <ConditionSetTrackerEdit />}
                    </Flex.ContainerV>
                </ProjectAssetPage>
            )}
        </Form>
    );
};

export { ConditionSetEdit };
