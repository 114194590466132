import * as React from 'react';
import { generatePath, Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Project } from 'reports/models/project';
import { Scenario } from 'reports/models/scenario';

import { ConditionSetsEmptyState } from 'reports/modules/project/components/condition_sets/ConditionSetsEmptyState';

const ConditionsBase = () => {
    const { project } = useOutletContext() as { project: Project };

    const { scenarioId } = useParams() as { scenarioId: string | undefined };
    if (scenarioId == null && project.primary_scenario_id != null) {
        const to = generatePath('/projects/:projectId/conditions/:scenarioId', {
            projectId: project.project_id.toString(),
            scenarioId: project.primary_scenario_id.toString(),
        });
        return <Navigate to={to} />;
    }

    const navigate = useNavigate();
    const navigateToConditionSetEdit = (project: Project, scenario: Scenario) => {
        const path = generatePath('/projects/:projectId/conditions/:scenarioId/edit', {
            projectId: project.project_id.toString(),
            scenarioId: scenario.scenario_id.toString(),
        });
        navigate(path);
    };

    return (
        <ConditionSetsEmptyState
            inlineControls={true}
            navigateToConditionSetEdit={navigateToConditionSetEdit}
            project={project}
        />
    );
};

export { ConditionsBase };
