import { selectors as authSelectors } from 'reports/modules/auth';
import { api as teamAPI, Team } from 'reports/models/team';
import { api as userAPI, selectors as userSelectors, User } from 'reports/models/user';
import { api as incentiveAPI } from 'reports/models/incentive';
import { Module, ModuleCharacterization } from 'reports/models/module';
import { PowerDevice, PowerDeviceCharacterization } from 'reports/models/power_device';
import { api as pdCharAPI } from 'reports/models/power_device/PowerDeviceCharacterization';
import { api as modCharAPI } from 'reports/models/module/ModuleCharacterization';
import { api as utilityRateAPI } from 'reports/models/utility_rate';
import { api as profileAPI } from 'reports/models/profile';
import { actions as projActions } from 'reports/modules/project';
import { actions as reportActions } from 'reports/modules/report';
import { api as finTemplateAPI } from 'reports/models/financial_template';
import { api as scenarioAPI } from 'reports/models/scenario';
import { api as reportAPI, selectors as repSelectors } from 'reports/models/report';
import { api as subAPI, Subscription } from 'reports/models/subscription';
import { api as projFinTemplateAPI } from 'reports/models/project_financial_template';

import { UnauthorizedError } from 'reports/modules/auth/permissions';

import { AppStore } from 'reports/store';
import { Params } from 'react-router';

const getUser = async (store: AppStore, searchParams: URLSearchParams): Promise<User> => {
    const state = store.getState();
    const email = searchParams.get('email');

    if (email) {
        const user: User | undefined = userSelectors.byEmail(state, email);
        return user ? user : await store.dispatch(userAPI.get({ email }));
    }
    return authSelectors.getUser(state)!;
};

const getTeam = async (store: AppStore, team_id: number): Promise<Team> =>
    await store.dispatch(teamAPI.get({ team_id }));

const getSubscription = async (store: AppStore, subscription?: Subscription) =>
    subscription ? await store.dispatch(subAPI.get({ external_id: subscription.external_id })) : undefined;

const loadScenario = async (store: AppStore, scenarioId: string | null) =>
    scenarioId ? await store.dispatch(scenarioAPI.get({ scenario_id: Number(scenarioId) })) : null;

const loadReport = async (store: AppStore, reportId: string | null) =>
    reportId ? await store.dispatch(reportAPI.get({ report_id: Number(reportId) })) : null;

const loadFullReport = async (store: AppStore, projectId: string, slug: string) => {
    let report = repSelectors.bySlug(store.getState(), slug);

    if (!report) {
        const potentialReports = await store.dispatch(
            reportAPI.index({
                q: slug.replace(/-/g, ' '),
            }),
        );

        const matchedReports = potentialReports.filter((r) => r.slug === slug);

        if (matchedReports == null || matchedReports.length !== 1) {
            throw new Error(`can't find report for ${slug}`);
        }
        report = matchedReports[0];
    }

    if (!report!.basic_report) {
        const user = authSelectors.getUser(store.getState());
        const canViewFinancials = user?.hasFinancialsAccess();
        if (user == null || !canViewFinancials) {
            throw new UnauthorizedError();
        }
    }

    // The report should already be loaded for the ProjectReports page but here we need to ensure that it's the latest
    // version and load its files. We can get all report configurations associated w/ the project by passing in projectId.
    return await store.dispatch(
        reportAPI.get({
            report_id: report!.report_id,
            load_files: true,
            project_id: projectId,
        }),
    );
};

const loadBookmarkedReports = async (store: AppStore) => await store.dispatch(reportAPI.index({ bookmarked: true }));

const loadIncentive = async (store: AppStore, incentiveId: string) =>
    isNaN(Number(incentiveId)) ? null : await store.dispatch(incentiveAPI.get({ incentive_id: Number(incentiveId) }));

const loadProfile = async (store: AppStore, profileId: string) =>
    isNaN(Number(profileId)) ? null : await store.dispatch(profileAPI.get({ profile_id: Number(profileId) }));

const loadUtilityRate = async (store: AppStore, utilityRateId: string) =>
    isNaN(Number(utilityRateId))
        ? null
        : await store.dispatch(utilityRateAPI.get({ utility_rate_id: Number(utilityRateId) }));

const loadFinTemplate = async (store: AppStore, finTemplateId: string) =>
    isNaN(Number(finTemplateId))
        ? null
        : await store.dispatch(finTemplateAPI.get({ financial_template_id: Number(finTemplateId) }));

const loadProject = async (store: AppStore, projectId: string) => {
    if (isNaN(Number(projectId))) {
        return null;
    }

    return await store.dispatch(projActions.loadProjectState(projectId));
};

const loadReportsProject = async (store: AppStore, projectId: string | null) => {
    if (!projectId) {
        const defaultProjectId = await store.dispatch(reportActions.getDefaultProjectId());
        if (defaultProjectId) {
            const projectState = await store.dispatch(projActions.loadProjectState(defaultProjectId));
            return {
                defaultProjectId,
                ...projectState,
            };
        }
        // If there are no projects, return an empty object.
        return {};
    }
    return await store.dispatch(projActions.loadProjectState(projectId));
};

const loadPowerDeviceCharacterizations = async (store: AppStore, powerDeviceId: number) =>
    store.dispatch(pdCharAPI.index({ power_device_id: powerDeviceId }));

const loadPowerDevice = async (
    store: AppStore,
    params: Params,
): Promise<{
    characterization?: PowerDeviceCharacterization | null;
    powerDevice?: PowerDevice | null;
}> => {
    if (isNaN(Number(params.powerDeviceId)) || isNaN(Number(params.characterizationId))) {
        return { powerDevice: null, characterization: null };
    }
    const powerDeviceId = Number(params.powerDeviceId);
    const characterizationId = Number(params.characterizationId);
    const characterizations = await loadPowerDeviceCharacterizations(store, powerDeviceId);
    const selectedCharacterization = characterizations.find(
        (char) =>
            char.power_device_id === powerDeviceId && char.power_device_characterization_id === characterizationId,
    );
    return selectedCharacterization
        ? { characterization: selectedCharacterization, powerDevice: selectedCharacterization.power_device }
        : { characterization: null, powerDevice: null };
};

const loadModuleCharacterizations = async (store: AppStore, moduleId: number) =>
    store.dispatch(modCharAPI.index({ module_id: moduleId }));

const loadModule = async (
    store: AppStore,
    params: Params,
): Promise<{ characterization?: ModuleCharacterization | null; module?: Module | null }> => {
    if (isNaN(Number(params.moduleId)) || isNaN(Number(params.characterizationId))) {
        return { characterization: null, module: null };
    }
    const moduleId = Number(params.moduleId);
    const characterizationId = Number(params.characterizationId);
    const characterizations = await loadModuleCharacterizations(store, moduleId);
    const selectedCharacterization = characterizations.find(
        (char) => char.module_id === moduleId && char.module_characterization_id === characterizationId,
    );
    return selectedCharacterization
        ? { characterization: selectedCharacterization, module: selectedCharacterization.module }
        : { characterization: null, module: null };
};

const loadSettingsData = async (
    store: AppStore,
    searchParams: URLSearchParams,
): Promise<{ subscription?: Subscription; team: Team; user: User }> => {
    const user = await getUser(store, searchParams);
    const team = await getTeam(store, user.team_id);

    const subscription = await getSubscription(store, team.latest_subscription || user.latest_subscription);

    return { subscription, team, user };
};

const loadFinConfigs = async (store: AppStore, projectId: number) => {
    const user = authSelectors.getUser(store.getState());
    const canViewFinancials = user?.hasFinancialsAccess();
    if (user == null || !canViewFinancials) {
        throw new UnauthorizedError();
    }
    return await Promise.all([store.dispatch(projFinTemplateAPI.index({ project_id: projectId }))]);
};

const loadFinConfig = async (store: AppStore, finConfigId: number) =>
    isNaN(Number(finConfigId))
        ? null
        : await store.dispatch(projFinTemplateAPI.get({ project_financial_template_id: Number(finConfigId) }));

export {
    loadBookmarkedReports,
    loadFinConfig,
    loadFinConfigs,
    loadFinTemplate,
    loadIncentive,
    loadModule,
    loadPowerDevice,
    loadProfile,
    loadProject,
    loadReport,
    loadReportsProject,
    loadFullReport,
    loadScenario,
    loadUtilityRate,
    loadSettingsData,
};
