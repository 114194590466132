// This file is intended to be a proof of concept. If you need to make changes
// in here, please ensure that you are working with the MBU epic owner.
import React from 'react';

import { useCallback, useMemo } from 'react';
import { generatePath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LockableNavigationItem } from 'frontend/routing/lockable-navigation-item';

import * as DS from '@aurorasolar/ds';

import { selectors as authSelectors } from 'reports/modules/auth';
import { IAppState } from 'reports/store';

const LIBRARIES_ROUTES = [
    '/profiles',
    '/modules',
    '/components',
    '/weather_sources',
    '/wires',
    '/reports',
    '/financial-models',
    '/utility-rates',
    '/incentives',
];

// Turn LIBRARIES_ROUTES above into type similar to SettingsPath.
type LibrariesPath = (typeof LIBRARIES_ROUTES)[number];

type SettingsPath =
    | '/settings/hs-admin/subscription'
    | '/settings/hs-admin/merge-teams'
    | '/settings/hs-admin/team-features'
    | '/settings/hs-admin/user-features'
    | '/settings/team'
    | '/settings/team/manage'
    | '/settings/team/billing'
    | '/settings/team/integrations'
    | '/settings/team/themes'
    | '/settings/user'
    | '/settings/user/changepassword'
    | '/settings/user/preferences';

type Path = LibrariesPath | SettingsPath;

const useNavigationItems = () => {
    const user = useSelector((state: IAppState) => authSelectors.getUser(state)!);
    const canViewFinancials = user.hasFinancialsAccess();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const params = useParams();
    const currentPathName = location.pathname;

    // We want to preserve administration or impersonation search params when navigating between
    // certain pages. Sometimes react-router misbehaves and doesn't keep a correct record of
    // searchParams, so we need to explicitly filter against as_user and email as opposed to just
    // passing searchParams into navigate.
    const navigateWithSearchParams = (path: string) => {
        const newSearchParams = new URLSearchParams();
        searchParams.get('as_user') && newSearchParams.set('as_user', searchParams.get('as_user')!);
        searchParams.get('email') && newSearchParams.set('email', searchParams.get('email')!);
        navigate({ pathname: path, search: `?${newSearchParams.toString()}` });
    };

    const routeIncludes = useCallback((pathName: Path) => currentPathName?.includes(pathName), [currentPathName]);
    const routeEndsWith = useCallback((pathName: Path) => currentPathName?.endsWith(pathName), [currentPathName]);

    const librariesAreActive = useCallback(
        () => LIBRARIES_ROUTES.some((subpath) => routeIncludes(subpath as Path)) && !routeIncludes('/projects'),
        [currentPathName],
    );

    const settingsDisabled = !user.role.can_view_settings;

    const contextualNavigationGroups = () => {
        if (routeIncludes('/projects') && !routeEndsWith('/projects')) {
            const projectId = params.projectId || null;

            return [
                {
                    items: [
                        {
                            action: () => navigate(generatePath('projects/:projectId', { projectId })),
                            active: !!projectId && location.pathname === `/projects/${projectId}`,
                            activeIcon: DS.IconDashboardFill,
                            children: 'Overview',
                            icon: DS.IconDashboard,
                        },
                        {
                            action: () => navigate('/designer'),
                            active: routeEndsWith('/designer'),
                            activeIcon: DS.IconDesignMode,
                            children: 'Designer',
                            icon: DS.IconDesignMode,
                        },
                        {
                            action: () => navigate(generatePath('projects/:projectId/conditions', { projectId })),
                            active: routeIncludes('/conditions'),
                            activeIcon: DS.IconShade,
                            children: 'Conditions',
                            icon: DS.IconShade,
                        },
                        {
                            action: () => navigate(generatePath('projects/:projectId/simulation', { projectId })),
                            active: routeEndsWith('/simulation'),
                            activeIcon: DS.IconPlugFill,
                            children: 'Simulations',
                            icon: DS.IconPlug,
                        },
                        {
                            action: () =>
                                navigate(generatePath('projects/:projectId/financial-configurations', { projectId })),
                            active: routeIncludes('/financial-configurations'),
                            activeIcon: DS.IconDollarCircleFill,
                            children: <LockableNavigationItem locked={!canViewFinancials} title="Financials" />,
                            icon: DS.IconDollarCircle,
                        },
                        {
                            action: () => navigate(generatePath('projects/:projectId/sharing', { projectId })),
                            active: routeEndsWith('/sharing'),
                            activeIcon: DS.IconUserFill,
                            children: 'Sharing',
                            icon: DS.IconUser,
                        },
                        {
                            action: () => navigate(generatePath('projects/:projectId/reports', { projectId })),
                            active: routeEndsWith('/reports') || routeIncludes('/report'),
                            activeIcon: DS.IconPaperLinesFill,
                            children: 'Reports & Proposals',
                            icon: DS.IconPaperLines,
                        },
                    ],
                },
            ];
        }

        if (librariesAreActive()) {
            return [
                {
                    title: 'Libraries',
                    items: [
                        {
                            action: () => navigate('/profiles'),
                            active: routeIncludes('/profiles'),
                            activeIcon: DS.IconUserFill,
                            children: 'Profiles',
                            icon: DS.IconUser,
                        },
                        {
                            action: () => navigate('/modules'),
                            active: routeIncludes('/modules'),
                            activeIcon: DS.IconSolarPanelFill,
                            children: 'Modules',
                            icon: DS.IconSolarPanel,
                        },
                        {
                            action: () => navigate('/components'),
                            active: routeIncludes('/components'),
                            activeIcon: DS.IconInverterFill,
                            children: 'Inverters & Optimizers',
                            icon: DS.IconInverter,
                        },
                        {
                            action: () => navigate('/weather_sources'),
                            active: routeEndsWith('/weather_sources'),
                            activeIcon: DS.IconSunFill,
                            children: 'Weather Sources',
                            icon: DS.IconSun,
                        },
                        {
                            action: () => navigate('/wires'),
                            active: routeEndsWith('/wires'),
                            activeIcon: DS.IconStringFill,
                            children: 'Wires',
                            icon: DS.IconString,
                        },
                    ],
                },
                {
                    title: 'Reports and proposals',
                    items: [
                        {
                            action: () =>
                                navigate('/reports', { state: { referrer: 'report_templates_library_sidebar' } }),
                            active: routeIncludes('/reports'),
                            activeIcon: DS.IconLayoutCustomFill,
                            children: <LockableNavigationItem locked={!canViewFinancials} title="Report Templates" />,
                            icon: DS.IconLayoutCustom,
                        },
                    ],
                },
                {
                    title: 'Quoting',
                    items: [
                        {
                            action: () =>
                                navigate('/financial-models', {
                                    state: { referrer: 'financial_models_library_sidebar' },
                                }),
                            active: routeIncludes('/financial-models'),
                            activeIcon: DS.IconMoneyFill,
                            children: <LockableNavigationItem locked={!canViewFinancials} title="Financial Models" />,
                            icon: DS.IconMoney,
                        },
                        {
                            action: () =>
                                navigate('/incentives', { state: { referrer: 'incentives_library_sidebar' } }),
                            active: routeIncludes('/incentives'),
                            activeIcon: DS.IconDollarCircleFill,
                            children: <LockableNavigationItem locked={!canViewFinancials} title="Incentives" />,
                            icon: DS.IconDollarCircle,
                        },
                        {
                            action: () =>
                                navigate('/utility-rates', { state: { referrer: 'utility_rates_library_sidebar' } }),
                            active: routeIncludes('/utility-rates'),
                            activeIcon: DS.IconGaugeFill,
                            children: <LockableNavigationItem locked={!canViewFinancials} title="Utility Rates" />,
                            icon: DS.IconGauge,
                        },
                    ],
                },
            ];
        }

        if (routeIncludes('/settings')) {
            const settingsRoutes = [
                {
                    title: 'Account',
                    items: [
                        {
                            action: () => navigateWithSearchParams('/settings/user'),
                            active: routeEndsWith('/settings/user') || routeEndsWith('/settings/user/changepassword'),
                            activeIcon: DS.IconUserFill,
                            children: 'Account Overview',
                            disabled: settingsDisabled,
                            icon: DS.IconUser,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/user/preferences'),
                            active: routeEndsWith('/settings/user/preferences'),
                            activeIcon: DS.IconLeadsFill,
                            children: 'Preferences',
                            disabled: settingsDisabled,
                            icon: DS.IconLeads,
                        },
                    ],
                },
                {
                    title: 'Team',
                    items: [
                        {
                            action: () => navigateWithSearchParams('/settings/team'),
                            active: routeEndsWith('/settings/team'),
                            activeIcon: DS.IconTeamFill,
                            children: 'Team Overview',
                            disabled: settingsDisabled,
                            icon: DS.IconTeam,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/team/manage'),
                            active: routeEndsWith('/settings/team/manage'),
                            activeIcon: DS.IconPartnerManagerFill,
                            children: 'Manage Team',
                            icon: DS.IconPartnerManager,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/team/integrations'),
                            active: routeEndsWith('/settings/team/integrations'),
                            activeIcon: DS.IconLayersFill,
                            children: 'Integrations',
                            disabled: settingsDisabled,
                            icon: DS.IconLayers,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/team/themes'),
                            active: routeEndsWith('/settings/team/themes'),
                            activeIcon: DS.IconImageFill,
                            children: 'Themes',
                            disabled: settingsDisabled,
                            icon: DS.IconImage,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/team/billing'),
                            active: routeEndsWith('/settings/team/billing'),
                            activeIcon: DS.IconMoneyFill,
                            children: 'Billing & Usage',
                            icon: DS.IconMoney,
                        },
                    ],
                },
            ];

            const adminRoutes = [
                {
                    title: 'HS Admin',
                    items: [
                        {
                            action: () => navigateWithSearchParams('/settings/hs-admin/subscription'),
                            active: routeEndsWith('/settings/hs-admin/subscription'),
                            activeIcon: DS.IconLoanFill,
                            children: 'Subscription',
                            icon: DS.IconLoan,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/hs-admin/merge-teams'),
                            active: routeEndsWith('/settings/hs-admin/merge-teams'),
                            activeIcon: DS.IconExternalLinkFill,
                            children: 'Merge Teams',
                            icon: DS.IconExternalLink,
                        },

                        {
                            action: () => navigateWithSearchParams('/settings/hs-admin/team-features'),
                            active: routeEndsWith('/settings/hs-admin/team-features'),
                            activeIcon: DS.IconTeamFill,
                            children: 'Team Features',
                            icon: DS.IconTeam,
                        },
                        {
                            action: () => navigateWithSearchParams('/settings/hs-admin/user-features'),
                            active: routeEndsWith('/settings/hs-admin/user-features'),
                            activeIcon: DS.IconUserFill,
                            children: 'User Features',
                            icon: DS.IconUser,
                        },
                    ],
                },
            ];

            return user && user.is_admin ? [...settingsRoutes, ...adminRoutes] : settingsRoutes;
        }

        return [];
    };

    const pageNavigation = useMemo<DS.INavigationItemGroup[]>(
        () => [
            {
                items: [
                    {
                        action: () => navigate('/projects'),
                        active: routeIncludes('/projects'),
                        activeIcon: DS.IconProjectFill,
                        children: 'Projects',
                        icon: DS.IconProject,
                    },
                    {
                        action: () => navigate('/profiles'),
                        active: librariesAreActive(),
                        activeIcon: DS.IconDatabaseFill,
                        children: 'Libraries',
                        icon: DS.IconDatabase,
                    },
                    {
                        action: () => navigate('/settings/team'),
                        active: routeIncludes('/settings'),
                        activeIcon: DS.IconGearFill,
                        children: 'Settings',
                        icon: DS.IconGear,
                    },
                ],
            },
        ],
        [currentPathName, routeIncludes],
    );

    const contextualNavigation = useMemo(
        () => ({
            groups: contextualNavigationGroups(),
        }),
        [currentPathName],
    );

    return { contextualNavigation, pageNavigation };
};

export { useNavigationItems };
