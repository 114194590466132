import React from 'react';
import { generatePath, Navigate, useNavigate } from 'react-router';
import { FinancialConfigurationList } from 'reports/modules/financials/components/FinancialConfigurationList';
import { useProjectContext } from './use-project';

const FinancialConfigurations = () => {
    const { project } = useProjectContext();
    const navigate = useNavigate();

    const navigateToConfig = (projectId, finConfigId) => {
        navigate(
            generatePath('/projects/:projectId/financial-configurations/:finConfigId', {
                projectId: projectId.toString(),
                finConfigId: finConfigId.toString(),
            }),
        );
    };

    const hasPrimary = !!project.primary_project_financial_template;
    if (hasPrimary) {
        const to = generatePath('/projects/:projectId/financial-configurations/:finConfigId', {
            projectId: project.project_id.toString(),
            finConfigId: project.primary_project_financial_template_id!.toString(),
        });
        return <Navigate to={to} />;
    }

    return <FinancialConfigurationList project={project} navigateToFinConfig={navigateToConfig} />;
};

export { FinancialConfigurations };
