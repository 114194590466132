import * as React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';

import { IAppState } from 'reports/types';
import { Project } from 'reports/models/project';
import { Scenario, selectors } from 'reports/models/scenario';

type ConditionSetContext = {
    project: Project;
    scenario: Scenario;
};

const ConditionSetBase = () => {
    const { project } = useOutletContext() as { project: Project };

    const scenarioToSelect = useLoaderData() as Scenario;
    const scenario = useSelector((state: IAppState) => selectors.byObject(state, scenarioToSelect));
    if (!scenario) {
        throw new Response('Not Found', { status: 404 });
    }

    return <Outlet context={{ project, scenario } satisfies ConditionSetContext} />;
};

export { ConditionSetBase, ConditionSetContext };
