import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@xstyled/styled-components';

import * as DS from '@aurorasolar/ds';

import { useToasters } from 'reports/modules/Toaster';
import { useNavigationItems } from 'frontend/routing/use-navigation-items';
import { HS_BRANDMARK, HS_BRANDMARK_SIZE, HS_LOGO, HS_LOGO_SIZE } from 'frontend/layout/branding';
import { userMenu } from 'frontend/layout/user-menu';

import * as auth from 'reports/modules/auth';

import { ExpiredBoundary } from 'reports/modules/auth/components';
import TeamProjectUsageListener from 'reports/modules/project_usage/TeamProjectUsage';
import { AddProjectsDialogContainer } from 'reports/components/dialog/components/AddProjectsDialogContainer';
import { TrialLimitsBanner } from 'frontend/layout/trial-limits-banner';
import { UpsellDialog } from 'frontend/libraries/upsell-dialog';
import { IAppState } from 'reports/types';
import { TOUBoundary } from 'frontend/layout/terms-of-use';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { Price } from 'reports/models/stripe';

const StyledPage = styled(DS.Page)`
    nav[data-component='navigation-bar'] {
        background-color: uiActionBar.defaultBgColor;
        grid-template-columns: auto auto auto;
        z-index: 1;
    }

    nav[data-component='navigation-bar'] button {
        color: uiButtonContextualNavigation.dropdownTextColor;
    }

    div[data-component='contextual-navigation'] {
        margin-top: -45px;
        z-index: 2;
    }

    main[data-subcomponent='page-content'] {
        overflow: hidden;
    }

    // Since we set margin-top to -45px, we set max-height to none to allow for the contextual navigation to grow to the
    // bottom of the page.
    div[data-subcomponent='contextual-navigation-body'] {
        max-height: none;
    }

    p {
        font-size: 14px;
    }

    a {
        font-weight: normal;
    }
`;

// We are mainly using PageContent as a wrapper for DS.useNavigation().
// If we try to snag setPageNavigationVisible within the Page component below,
// setPageNavigationVisible will be a NOOP.
const PageContent = ({ children }: React.PropsWithChildren<{}>) => {
    const { setPageNavigationVisible } = DS.useNavigation();
    const { contextualNavigation } = useNavigationItems();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    // This needs to be updated to Object.fromEntries(searchParams.entries());
    // when we get our TS target lib updated to something more modern.
    const paramsToObject = (entries) => {
        const result = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return result;
    };

    return (
        <>
            {children}
            <AddProjectsDialogContainer
                onClose={() => {
                    searchParams.delete('dialog');
                    setSearchParams(searchParams);
                }}
                onOpen={() => setPageNavigationVisible(false)}
                routeName={location.pathname}
                searchParams={paramsToObject(searchParams)}
            />
            <UpsellDialog />
            <DS.ContextualNavigation contextualNavigation={contextualNavigation} />
            <Outlet />
        </>
    );
};

const Page = React.memo(({ children }: React.PropsWithChildren<{}>) => {
    const { pageNavigation } = useNavigationItems();
    const navigate = useNavigate();
    const location = useLocation();
    useToasters();

    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const logout = () => dispatch(auth.api.logout({}));

    const impersonatedUserEmail = useSelector((state) => auth.selectors.asUserOverride(state as IAppState));
    const administeredUserEmail = searchParams.get('email');

    // This can be moved back into ExpiredBoundary once we have removed the old router.
    const navigateToCheckoutFlow = (interval: string, price: Price) => {
        const billingSearchParams = new URLSearchParams({
            dialog: 'initial',
            interval: interval,
            product: price.product.id,
            referrer: ReferrerTypes.expired_account_dialog,
        });
        return navigate({ pathname: '/settings/team/billing', search: `?${billingSearchParams.toString()}` });
    };

    return (
        <>
            <ExpiredBoundary routeName={location.pathname} user={user} navigateToCheckoutFlow={navigateToCheckoutFlow}>
                <TOUBoundary>
                    <TeamProjectUsageListener />
                    <StyledPage
                        brandmarkIcon={HS_BRANDMARK}
                        brandmarkIconProps={HS_BRANDMARK_SIZE}
                        globalNavigation={{
                            showUserMenu: true,
                            userMenu: userMenu(logout, administeredUserEmail, impersonatedUserEmail),
                        }}
                        logoIcon={HS_LOGO}
                        logoIconProps={HS_LOGO_SIZE}
                        pageNavigation={pageNavigation}
                        variant="tertiary"
                    >
                        <TrialLimitsBanner />
                        <PageContent>{children}</PageContent>
                    </StyledPage>
                </TOUBoundary>
            </ExpiredBoundary>
        </>
    );
});

export { Page };
